<template>
  <AuthLayout>
    <template #form>
      <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">
        Faça seu login
      </h2>
      <h6 class="subtitle-1">
        Utilize sua conta institucional.
      </h6>
      <div class="text-center mt-6 mb-10">
        <v-btn color="primary" depressed class="button-google" @click="loginGoogle()">
          <img src="@/assets/images/logo/g-logo.png" rounded class="icon-google" />
          <span class="text-google font-weight-medium">Entrar com o Google</span>
        </v-btn>
      </div>
      <v-row align="center" class="mb-6 px-3">
        <v-divider />
        <span class="mx-2" style="color: #00000099">ou</span>
        <v-divider />
      </v-row>
      <v-form @keyup.native.enter="submit" ref="form" v-model="valid" lazy-validation>
        <v-text-field v-model="form.email" :rules="[rules.email, rules.required]" validate-on-blur label="E-mail" class="mt-4"
          name="email" dense required outlined></v-text-field>
        <v-text-field v-model="form.password" :rules="[rules.password]" validate-on-blur label="Senha" dense name="password"
          required outlined @click:append="() => (show1 = !show1)" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :type="show1 ? 'text' : 'password'"></v-text-field>
        <div v-show="loginAttemptRole()" class="mb-5 tw-flex tw-justify-center">
          <vue-recaptcha @verify="onVerify" @expired="onExpired" ref="recaptcha" :sitekey="sitekey" />
        </div>
        <v-btn :disabled="!valid" color="info" block class="mr-4" submit outlined @click="submit">Entrar</v-btn>
      </v-form>
      <div class="mt-5 text-center">
        <a @click="$router.push('/password/forgot')">
          <small> Esqueci minha senha </small>
        </a>
      </div>
      <div v-if="showEndpointInput" class="mt-5 text-center">
        <small class="font-weight-bold">Insira um endpoint válido</small>
        <v-text-field v-model="endpointInput" validate-on-blur label="URL do Endpoint" dense outlined
          @click:append="changeEndpoint()" :append-icon="'mdi-swap-horizontal'"></v-text-field>
      </div>
    </template>
  </AuthLayout>
</template>

<script>
import { mapMutations } from 'vuex'
import { VueRecaptcha } from 'vue-recaptcha'
import baseAPI from '@/libs/baseApi.js'
import AuthLayout from '@/components/auth/AuthLayout.vue'
import { rules } from '@/validators/form-rules'

export default {
  components: {
    VueRecaptcha,
    AuthLayout
  },
  data: () => ({
    valid: true,
    show1: false,
    showEndpointInput: false,
    endpointInput: '',
    form: {
      email: '',
      password: ''
    },
    rules,
    checkbox: false,
    sitekey: '6LcUV-gjAAAAACVoa9T8H1RF7T_S4Au967yEYL7L',
    tokenRecaptcha: '',
    loginAttempts: 0,
    maxLoginAttempts: 1
  }),
  methods: {
    ...mapMutations(['setLoading']),
    onVerify (response) {
      this.tokenRecaptcha = response
    },
    clearTokenRecaptcha () {
      this.tokenRecaptcha = ''
    },
    onExpired () {
      this.clearTokenRecaptcha()
    },
    resetRecaptcha () {
      this.clearTokenRecaptcha()
      this.$refs.recaptcha.reset() // Direct call reset method
    },
    validateRecaptcha () {
      if (!this.tokenRecaptcha) {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Preencha o recaptcha'
        })
      }
      return this.tokenRecaptcha
    },
    addLoginAttempt () {
      this.loginAttempts += 1
    },
    loginAttemptRole () {
      return this.loginAttempts >= this.maxLoginAttempts
    },
    async submit () {
      this.$refs.form.validate()
      if (this.$refs.form.validate(true)) {
        if (this.loginAttemptRole() && !this.validateRecaptcha()) {
          return
        }
        this.addLoginAttempt()
        try {
          this.setLoading(true)
          const request = await this.$axios.post('/auth/login', this.form)
          await this.tokenLogin(request.data.access_token)
        } catch (error) {
          this.resetRecaptcha()
          this.setLoading(false)
          if (error.response.status === 403) {
            const blockedUser = 'Usuário bloqueado ou inativo, contate seu administrador.'
            return await this.$store.dispatch('alert', { color: 'red', msg: blockedUser })
          }
          let msg = 'Erro na conexão, tente novamente'
          if (error.response.data.error === 'Unauthorized') {
            msg = 'Email ou senha incorretos'
          }
          this.$store.dispatch('alert', { color: 'red', msg })
        } finally {
        }
      } else {
        this.$store.dispatch('alert', {
          color: 'red',
          msg: 'Preencha todos os campos de login'
        })
      }
    },
    async tokenLogin (token) {
      this.setLoading(true)
      this.$store.commit('setUserToken', token)
      this.$axios.defaults.headers.Authorization = 'bearer ' + this.$store.getters.token
      await this.$nextTick()
      await this.$router.push({ path: '/' })
    },
    loginGoogle () {
      const newWindow = this.openWindow('', 'message')
      newWindow.location.href = baseAPI + '/auth/oauth/google'
    },
    onMessage (e) {
      if (!e.data.access_token) {
        return
      }

      this.tokenLogin(e.data.access_token)
    },
    openWindow (url, title, options = {}) {
      if (typeof url === 'object') {
        options = url
        url = ''
      }

      options = { url, title, width: 600, height: 720, ...options }

      const dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : window.screen.left
      const dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : window.screen.top
      const width =
        window.innerWidth || document.documentElement.clientWidth || window.screen.width
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        window.screen.height

      options.left = width / 2 - options.width / 2 + dualScreenLeft
      options.top = height / 2 - options.height / 2 + dualScreenTop

      const optionsStr = Object.keys(options)
        .reduce((acc, key) => {
          acc.push(`${key}=${options[key]}`)
          return acc
        }, [])
        .join(',')

      const newWindow = window.open(url, title, optionsStr)

      if (window.focus) {
        newWindow.focus()
      }

      return newWindow
    },
    keyDown (e) {
      if (e.keyCode === 27) {
        this.showEndpointInput = true
      }
    },
    changeEndpoint () {
      localStorage.setItem('baseAPI', this.endpointInput)
      return window.location.reload()
    }
  },
  mounted () {
    window.addEventListener('message', this.onMessage, false)
    if (process.env.VUE_APP_TESTING !== 'false') {
      window.addEventListener('keydown', this.keyDown)
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.onMessage)
    window.removeEventListener('keydown', this.keyDown)
  }
}
</script>
<style scoped>
.button-google {
  height: 50px !important;
  padding: 1px 1px !important;
  width: 100% !important;
}

.icon-google {
  position: absolute;
  left: 0;
  width: auto;
  height: 48px;
  margin-right: 24px;
  background-color: #fff;
  padding: 12px 12px !important;
  border-radius: 3px;
}

.text-google {
  font-size: 16px;
  margin-left: 22px;
}
</style>
