const rules = {
  required: (v) => (v && !!v.trim()) || 'Este campo é obrigatório.',
  email: (v) => {
    if (!/.+@.+\..+/.test(v)) return 'O e-mail deve ser válido.'
    if (v.indexOf(' ') >= 0) return 'Não são permitidos espaços.'
  },
  password: (v) => (v && !!v.trim()) || 'A senha é obrigatória.'
}

export {
  rules
}
